import React from "react";
import Products from "../../components/Products";
import Layout from "../../components/Layout";
import MyHelmet from "../../context/MyHelmet";

export default () => {
  return (
    <div>
      <MyHelmet page="products" language="de" />
      <Layout>
        <Products data={[]} />
      </Layout>
    </div>
  );
};
